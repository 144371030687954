import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: `
        <div class="loader" [style.width]="size">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
        </div>
    `,
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
    @Input() size: string;
}
